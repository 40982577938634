import { defineStore } from 'pinia'
import Queue from '@uicommon/utils/queue'
import useIsLoadingStore from '@uicommon/stores/useIsLoadingStore'
import { useHhaStore } from '.'
import { presentFixedDecimalAmount, presentFullHolofuelAmount } from 'src/utils'
import { presentTransaction, isHostingInvoice, getTimestamp, getValueFromEnum } from 'src/utils/transaction'
import wait from 'waait'

// Must match the role ID defined in happ.yaml of holofuel
// eg https://github.com/Holo-Host/holofuel/blob/e1db8e5220884c1ed0e40319b53f06dbb7ede94e/happ.yaml#L6
const HOLOFUEL_ROLE_ID =  'holofuel'

const mutatingZomeCallQueue = new Queue()

const makeUseHolofuelStore = ({ useHoloStore }) => {
  
  const callZomeMutation = args =>
    mutatingZomeCallQueue.enqueue_and_wait(() => useHoloStore().callZome({
      ...args,
      role_name: HOLOFUEL_ROLE_ID
    }))

  const callZome = args =>
    useHoloStore().callZome({
      ...args,
      role_name: HOLOFUEL_ROLE_ID
    })

  return defineStore('holofuel', {
    state: () => ({
      nickname: null,
      nicknames: {},
      ledger: {},
      actionableHostingInvoices: [],
      completedHostingInvoices: [],
      acceptedHostingInvoices: [],
      failedHostingInvoices: [],
    }),
    getters: {
      getMyProfileIsLoading: () => 
        useIsLoadingStore().isLoading({ zome_name: 'profile', fn_name: 'get_my_profile' }),
      invoicesAreLoading: () =>
        useIsLoadingStore().isLoading({ zome_name: 'transactor', fn_name: 'get_actionable_transactions' })
        || useIsLoadingStore().isLoading({ zome_name: 'transactor', fn_name: 'get_completed_transactions' }),            
      balance: state => presentFixedDecimalAmount(state.ledger.available),
      fullBalance: state => presentFullHolofuelAmount(state.ledger.available),
      fullBalanceNum: state => state.ledger.available
    },
    actions: {
      async loadMyNickname () {
        const callZomeArgs = {
          zome_name: 'profile',
          fn_name: 'get_my_profile',
          payload: null
        }

        try {
          const myProfile = await callZome(callZomeArgs)

          this.nickname = myProfile.nickname
        } catch (e) {
          console.error('Error calling get_my_profile', e)
        }
      },

      async updateMyNickname (nickname) {
        // update optimistically
        const old_nickname = this.nickname

        this.nickname = nickname
        
        const callZomeArgs = {
          zome_name: 'profile',
          fn_name: 'update_my_profile',
          payload: {
            nickname
          }
        }

        try {
          const myProfile = await callZomeMutation(callZomeArgs)
          // this should be redundant, but we use hc as final source of truth
          this.nickname = myProfile.nickname
        } catch (e) {
          console.error('Error calling update_my_profile', e)
          this.nickname = old_nickname
        }
      },
      async loadLedger () {
        const callZomeArgs = {
          zome_name: 'transactor',
          fn_name: 'get_ledger',
          payload: null
        }

        const result = await callZome(callZomeArgs)
        this.ledger = result
      },

      async loadInvoices () {
        await this.loadCompletedHostingInvoices()
        await this.loadActionableHostingInvoices()
        await this.loadPendingHostingInvoices()
        this.failedHostingInvoices = [] // Reset this because any failed transactions will have moved back to one of the other buckets at this point
      },

      async loadActionableHostingInvoices () {
        const callZomeArgs = {
          zome_name: 'transactor',
          fn_name: 'get_actionable_transactions',
          payload: null
        }

        const result = await callZome(callZomeArgs)

        this.actionableHostingInvoices = result.invoice_actionable.map(presentTransaction(useHhaStore().getHappName)).filter(isHostingInvoice)
      },

      async loadCompletedHostingInvoices () {
        const callZomeArgs = {
          zome_name: 'transactor',
          fn_name: 'get_completed_transactions',
          payload: null
        }

        const result = await callZome(callZomeArgs)

        this.completedHostingInvoices = result.filter(isHostingInvoice).map(presentTransaction(useHhaStore().getHappName))
      },

      async loadPendingHostingInvoices () {
        const callZomeArgs = {
          zome_name: 'transactor',
          fn_name: 'get_pending_transactions',
          payload: null
        }

        const result = await callZome(callZomeArgs)

        this.acceptedHostingInvoices = result.accepted.filter(isHostingInvoice).map(transaction => ({...presentTransaction(useHhaStore().getHappName)(transaction), status: 'Awaiting Countersigning'}))
      },

      async acceptTransaction (transaction) {
        const timestamp = getTimestamp()
        const expiration_date = timestamp + 1_000_000_000_000_000

        try {
          const accept_result = await callZomeMutation( {
            zome_name: 'transactor',
            fn_name: 'accept_transaction',
            payload: {
              address: transaction.id,
              timestamp,
              expiration_date
            }
          })

          this.actionableHostingInvoices = this.actionableHostingInvoices.filter(actionableInvoice => actionableInvoice.id !== transaction.id)
          this.acceptedHostingInvoices.push({...transaction, status: 'Awaiting Countersigning'})
        
          const complete_result = await callZomeMutation( {
            zome_name: 'transactor',
            fn_name: 'complete_transactions',
            payload: accept_result
          })

          if (getValueFromEnum(complete_result) !== 'Successful') {            
            throw new Error ('Countersigning failed')
          }

          this.acceptedHostingInvoices = this.acceptedHostingInvoices.filter(actionableInvoice => actionableInvoice.id !== transaction.id)
          this.completedHostingInvoices.push({...transaction, status: 'Paid'})
        } catch (e) {
          this.actionableHostingInvoices = this.actionableHostingInvoices.filter(actionableInvoice => actionableInvoice.id !== transaction.id)
          this.acceptedHostingInvoices = this.acceptedHostingInvoices.filter(actionableInvoice => actionableInvoice.id !== transaction.id)
          this.failedHostingInvoices.push(transaction)
        }
      },

      async bulkPayInvoices (invoices) {
        const transactionDelay = Number(window.BULK_PAY_DELAY) || 5_000
        console.log("bulk pay transaction delay:", transactionDelay)

        for (let i = 0; i < invoices.length; i++) {
          this.acceptTransaction(invoices[i])
          mutatingZomeCallQueue.wait(transactionDelay)
        }
      },
    },
  })
}

export default makeUseHolofuelStore
