import yaml from 'js-yaml'
import { pick } from 'lodash'
import moment from 'moment'
import { presentTailOfHash, presentFixedDecimalAmount } from '.'

export const getValueFromEnum = enumObject => {
  const keys = Object.keys(enumObject)

  if (keys.length === 0) {
    throw new Error(
      `Tried to get enum value for object with no keys: ${enumObject}`
    )
  } else if (keys.length > 1) {
    throw new Error(
      `Tried to get enum value for object with multiple keys: ${enumObject}`
    )
  }

  return keys[0]
}

export const presentDate = date => {
  if (!date.isSame) {
    return date
  }
  const isToday = date.isSame(moment(), 'day')
  if (isToday) {
    return date.format('HH:mm')
  } else {
    return date.format('DD MMM')
  }
}

export const parseHostingInvoiceNote = note => {
  const parsed_note = yaml.load(note)

  if (parsed_note?.length && parsed_note.length === 2) {
    return parsed_note[1]
  }

  throw new Error('Note is not in recognized hosting invoice format')
}

// NB: this is a different function to `presentTransaction` in the holofuel ui
export const presentTransaction = getHappName => transaction => {
  const formattedAmount = presentFixedDecimalAmount(transaction.amount)
  const formattedId = presentTailOfHash(transaction.id)

  const status = getValueFromEnum(transaction.status) === 'Completed' ? 'Paid' : 'Unpaid'

  const dateMoment = moment(Math.floor(Number(transaction.created_date) / 1_000))
  const date = presentDate(dateMoment)

  let invoiceData = {}
  
  if (isHostingInvoice(transaction)) {
    try {
      invoiceData = parseHostingInvoiceNote(transaction.note)
    } catch (e) {
      console.error(e)
      console.error(`failed to parse note as yaml: ${transaction.note}`)
    }
  }

  const happId = invoiceData.hha_id

  // If we can't find the happ name, provide the hha_id instead
  const happName = getHappName(happId) 
    || (happId 
      ? presentTailOfHash(happId) 
      : 'Unknown')

  return {
    ...pick(transaction, ['id', 'amount', 'proof_of_service', 'counterparty']),
    formattedAmount,
    formattedId,
    date,
    status,
    happName,
    happId,
    dateMoment    
  }
}

export const isHostingInvoice = transaction => !!transaction.proof_of_service

export const getTimestamp = () =>
  new Date().getTime() * 1000

export const getDueDate = transaction => transaction.dateMoment.add(7, 'days')

export const getDateLabel = aMoment => aMoment.format("YYYY-MM-DD")
