export const categoryList = [
  'Adult', 
  'Not-For-Profit', 
  'Books', 
  'Medical', 
  'Business',
  'Music',
  'Developer Tools',
  'Crypto',
  'Navigation',
  'Education',
  'News',
  'Entertainment',
  'Photo & Video',
  'Finance',
  'FinTech',
  'Productivity',
  'Food & Drink',
  'Reference',
  'Games',
  'Gaming',
  'Gambling',
  'Graphics & Design',
  'Shopping',
  'Health & Fitness',
  'Social Networking',
  'Lifestyle',
  'Sports',
  'Kids',
  'Travel',
  'Magazines & Newspapers',
  'Utilities',
  'Weather',
  'Open-Source',
]

export const mockHApps = [{
  id: 'ee9aa13e-9ed2-461c-9672-6363cfa8eb4b',
  bundle_url: 'https://www.zshapp.happ',
  dnas: [],
  exclude_jurisdictions: true,
  name: 'Talking Stickies',
  description: 'Trello like hApp for white boarding ideas',
  logoUrl: 'https://avatars.steamstatic.com/a9a657eb0852c047958c5857827dda274df15031_full.jpg',
  isPaused: false,
  is_draft: false,
  jurisdictions: [],
  last7daysEarnings: 45000,
  storage: 14,
  usage : {
    cpu: 786432,
    bandwidth: 123456
  }
},
{
  id: '6cf5ec20-7c9c-4154-a36d-b90c8aca58cf',
  bundle_url: 'https://www.zshapp.happ',
  dnas: [],
  exclude_jurisdictions: true,
  name: 'No logo hApp',
  description: '',
  logoUrl: null,
  isPaused: false,
  is_draft: true,
  jurisdictions: [],
  last7daysEarnings: 500,
  storage: 2,
  usage : {
    cpu: 32,
    bandwidth: 56
  }
},
{
  id: '379ca9ed-0a67-467a-b627-913d28be68a1',
  bundle_url: 'https://www.zshapp.happ',
  dnas: [],
  exclude_jurisdictions: true,
  name: 'Paused hApp',
  description: 'A paws-ed hApp will it ever be un-paws-ed!?',
  logoUrl: 'https://dox.design/wp-content/uploads/2019/08/4-768x736.png',
  isPaused: true,
  is_draft: false,
  jurisdictions: [],
  last7daysEarnings: 0,
  storage: 0,
  usage : {
    cpu: 0,
    bandwidth: 0
  }
},
{
  id: 'c8ceb15f-2d80-4f3d-9b5b-068ca6b94090',
  bundle_url: 'https://www.zshapp.happ',
  dnas: [],
  exclude_jurisdictions: true,
  name: 'Used a lot hApp',
  description: 'This hApp has large usage values.',
  logoUrl: 'https://images-platform.99static.com/qMCoqzgUB8g7j7RIy_jBmAiSaTQ=/500x500/top/smart/99designs-contests-attachments/21/21693/attachment_21693639',
  isPaused: true,
  is_draft: false,
  jurisdictions: [],
  last7daysEarnings: 3_987_123_212_111,
  storage: 123_567_111,
  usage : {
    cpu: 123_456_789_111,
    bandwidth: 111_222_333_444_111
  }
},
{
  id: '4598bce8-8ff9-44b4-ac7d-869fd88ebfa0',
  bundle_url: 'https://www.zshapp.happ',
  dnas: [],
  exclude_jurisdictions: true,
  name: 'Holobaloo',
  description: 'Slack/Discord like chat hApp for conversing with co-workers, friends, and family.',
  logoUrl: 'http://hullabalooagency.com/wp-content/themes/web/dist/static/images/common/logo.svg',
  isPaused: false,
  is_draft: false,
  jurisdictions: [],
  last7daysEarnings: 3_987_123_212,
  storage: 123_567_987_123,
  usage : {
    cpu: 123_456_789,
    bandwidth: 111_222_333_444
  }
},
{
  id: 'e88b004a-a20c-42c4-96d3-2a160dac9719',
  bundle_url: 'https://www.zshapp.happ',
  dnas: [],
  exclude_jurisdictions: true,
  name: 'Holopain',
  description: 'Holochain based 2D platformer',
  logoUrl: 'https://i.ebayimg.com/images/g/dT0AAOSwPt9hi1vC/s-l1600.jpg',
  isPaused: false,
  is_draft: false,
  jurisdictions: [],
  last7daysEarnings: -500,
  storage: -500,
  usage : {
    cpu: -500,
    bandwidth: -500
  }
},
{
  id: '232f91fa-0a84-42aa-be21-9db32d58a1a1',
  bundle_url: 'https://www.zshapp.happ',
  dnas: [],
  name: 'San Holo',
  description: 'Running out of hApp ideas so going with bad puns instead',
  logoUrl: 'https://i.ebayimg.com/images/g/nqAAAOSwWlNitX19/s-l1600.jpg',
  isPaused: false,
  is_draft: true,
  last7daysEarnings: 888_888_888,
  storage: 888_888_888,
  usage : {
    cpu: 888_888_888,
    bandwidth: 888_888_888
  }
}];

